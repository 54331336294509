<template>
  <div class="content-provider-detail-oc global-content-detail-all">
    <div class="global-content-detail" >
    <div id="iHeaderDetail" ref="iHeaderDetail">
      <header-detail-component-global sTextDetail="Detalle de una empresa: " :sBackHref="sBackHref"
        :bWithPathOrNameRoute="bWithPathOrNameRoute" />
      <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
    </div>
      <provider-general-information-global v-if="sTypeActor" :iTab="0" />
      <person-general-information-global v-else :iTab="1" />
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  props: {
    itemsBreadcrumbs: Array,
    sBackHref: String,
    bWithPathOrNameRoute: { type: Boolean, default: false },
  },
  data() {
    return {
      tab: 0,
      iHeaderDetail: 0,
      screenHeight: 0,
      heightMax: 0,
    };
  },
  beforeMount() { this.setTabToProvider() },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.$store.commit("setsNameDetail", "");
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.iHeaderDetail !== undefined) {
          this.heightMax = this.$refs.iHeaderDetail.clientHeight;
          this.heightMax = this.heightMax + 123; //123 IS NAVBAR
        }
        if (window.innerWidth > 599) {
          this.screenHeight = window.innerHeight - 100;
        } else {
          this.screenHeight = window.innerHeight - 100;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setTabToProvider() {
      let tab = 0;
      this.sTypeActor ? tab = 0 : tab = 1;
      this.$store.commit("setTabPosition", { sTabPosition: tab });

    }
  },
  computed: {
    sTypeActor() {
      return this.$store.state.sTypeActor === "ENTERPRISE"
    }
  }
};
</script>

<style scoped>
</style>